import logo from '../assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import React, { useEffect } from 'react';

export default function Header() {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    console.log(' i18n.language = ' + i18n.language);
    changeLanguage(i18n.language === undefined ? 'en' : i18n.language);
  }, [i18n.language]);
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <header className="App-header">
      <div className="head-box">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
      </div>
      <div className="head-box lang">
        <div className={`langbtn ${i18n.language === 'en' && 'defaultLn'}`} onClick={() => changeLanguage('en')}>
          EN
        </div>
        |
        <div className={`langbtn ${i18n.language === 'th' && 'defaultLn'}`} onClick={() => changeLanguage('th')}>
          ไทย
        </div>
      </div>
    </header>
  );
}
