import AppHeader from '../components/appheader';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';

import etsLogo from '../assets/images/ets/logo.png';
import ioslogo from '../assets/images/icon_appstore.svg';
import playlogo from '../assets/images/icon_googleplay.svg';
import gallerylogo from '../assets/images/icon_huaweiappgallery.svg';

import bullet from '../assets/images/bullet.png';

import phone_and_timestamp from '../assets/images/ets/phone_and_timestamp.png';
import step_1 from '../assets/images/ets/step_1.png';
import step_2 from '../assets/images/ets/step_2.png';
import step_3 from '../assets/images/ets/step_3.png';
import step_4 from '../assets/images/ets/step_4.png';
import step_5 from '../assets/images/ets/step_5.png';
import admin from '../assets/images/ets/admin.png';
import admin_report from '../assets/images/ets/admin_report.png';
import _report_pdf from '../assets/images/ets/_report_pdf.png';
import admin_clear from '../assets/images/ets/admin_clear.png';
import _clear_entries from '../assets/images/ets/_clear_entries.png';
import ex_1 from '../assets/images/ets/ex_1.png';
import ex_2 from '../assets/images/ets/ex_2.png';
import ex_3 from '../assets/images/ets/ex_3.png';
import ex_4 from '../assets/images/ets/ex_4.png';

export default function ETS() {
  const { t } = useTranslation();

  const ioslink = 'https://apps.apple.com/us/app/easy-timestamp/id1016324747?ls=1';
  const playlink = 'https://play.google.com/store/apps/details?id=com.nathtale.easyTimeStamp';
  const gallerylink = 'https://appgallery.huawei.com/#/app/C102697373';

  return (
    <div className="app-page">
      <AppHeader />

      <div class="appContainer ets">
        <img src={etsLogo} alt="ets_logo" />
        <h1>{t('Easy TimeStamp')}</h1>

        <div className="subtitle">{t('Help you log your daily activities.')}</div>
        <div className="store-play">
          <div className="store-link">
            <a href={ioslink} target="_blank" rel="noreferrer">
              <img src={ioslogo} alt="ios_logo" />
            </a>
          </div>
          <div className="store-link">
            <a href={playlink} target="_blank" rel="noreferrer">
              <img src={playlogo} alt="play_logo" />
            </a>
          </div>
          <div className="store-link">
            <a href={gallerylink} target="_blank" rel="noreferrer">
              <img src={gallerylogo} alt="gallery_logo" />
            </a>
          </div>
        </div>

        <div>
          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('WHY Easy TimeStamp')}</span>
          </div>
          <div>{t('It makes your phone work as a timestamp machine.')}</div>

          <div className="bigImg">
            <img src={phone_and_timestamp} alt="phone_and_timestamp" />
          </div>
          <div>
            <div>{t('You can use to log your daily activities.')}</div>
            <div>{t('It is easy to use.')}</div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('STEP 1')}</span> <div>{t('Just click add')}</div>
            </div>
            <div>
              <img src={step_1} alt="step_1" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('STEP 2')}</span>
              <div>{t('Type whatever you want to log then click save')}</div>
            </div>
            <div>
              <img src={step_2} alt="step_1" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('STEP 2 (OPTION)')}</span>
              <div>{t('In case you always add same timestamp, you can use add quicklist')}</div>
            </div>
          </div>
          <div>
            <img src={step_3} alt="step_3" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('STEP 2 (OPTION)')}</span>
            <div>{t('Use Quicklist')}</div>
          </div>
          <div>
            <img src={step_4} alt="step_4" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('STEP 3')}</span>
            <div>{t('Click PDF button to export file PDF as a report of your logs')}</div>
          </div>
          <div>
            <img src={step_5} alt="step_5" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('ENTRY MENU')}</span>
          </div>
          <div>{t('Export reports & Clear entries.')}</div>
          <div>
            <img src={admin} alt="admin" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('EXPORT REPORTS')}</span>
          </div>
          <div>{t('daily, monthly, yearly, or all of the reports.')}</div>
          <div>
            <img src={admin_report} alt="admin_report" />
          </div>

          <div>{t('An example of a daily report.')}</div>
          <div>
            <img src={_report_pdf} alt="_report_pdf" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('CLEAR ENTRIES')}</span>
          </div>
          <div>{t('daily, monthly, yearly, or all of the entries.')}</div>
          <div>
            <img src={admin_clear} alt="admin_clear" />
          </div>

          <div>{t('An example of clearing a daily entry.')}</div>
          <div>
            <img src={_clear_entries} alt="_clear_entries" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('If you run an office and you want a timestamp')}</span>
          </div>
          <div>{t('Your staff can use this app to stamp work time')}</div>
          <div>{t('Use AddQuickList to add timestamp faster.')}</div>
          <div>
            <img src={ex_1} alt="ex_1" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('If you usually work at home')}</span>
          </div>
          <div>{t('You can use Easy Timestamp to log your working time.')}</div>
          <div>{t('It helps you know how long you work each day.')}</div>
          <div>
            <img src={ex_2} alt="ex_2" />
          </div>

          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('If you own a restaurant')}</span>
            <div>{t('You can use Easy Timestamp to log your orders.')}</div>
            <div>{t('Doing business, even a second is important.')}</div>
            <div>
              <img src={ex_3} alt="ex_3" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('If you love to be healthy')}</span>
            </div>
            <div>{t('You can use Easy Timestamp to log your meal time.')}</div>
            <div>{t('Eating on time is crucial')}</div>
            <div>{t('This app can help you log your meal time easily')}</div>
            <div>
              <img src={ex_4} alt="ex_4" />
            </div>
          </div>
        </div>

        <div className="store-play">
          <div className="store-link">
            <a href={ioslink} target="_blank" rel="noreferrer">
              <img src={ioslogo} alt="ios_logo" />
            </a>
          </div>
          <div className="store-link">
            <a href={playlink} target="_blank" rel="noreferrer">
              <img src={playlogo} alt="play_logo" />
            </a>
          </div>
          <div className="store-link">
            <a href={gallerylink} target="_blank" rel="noreferrer">
              <img src={gallerylogo} alt="gallery_logo" />
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
