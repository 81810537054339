import AppHeader from '../components/appheader';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';
import efsLogo from '../assets/images/efs/logo.png';
import ioslogo from '../assets/images/icon_appstore.svg';
import bullet from '../assets/images/bullet.png';

import easyforsale from '../assets/images/efs/easyforsale.png';

import Setting_main_menu_1 from '../assets/images/efs/Setting_main_menu_1.png';
import Setting_1shop_nameSetting from '../assets/images/efs/22_Setting_1shop_nameSetting.png';
import Setting_2currency from '../assets/images/efs/23_Setting_2currency.png';
import Setting_3paperSize from '../assets/images/efs/24_Setting_3paperSize.png';

import Setting_main_menu_2 from '../assets/images/efs/Setting_main_menu_2.png';
import product from '../assets/images/efs/26_product.png';

import ProductToEdit from '../assets/images/efs/ProductToEdit.png';
import editProduct from '../assets/images/efs/27_editProduct.png';

import sell from '../assets/images/efs/1_sell.png';
import search from '../assets/images/efs/4_search.png';
import sell_check from '../assets/images/efs/2_1sell_check.png';
import SellEdit from '../assets/images/efs/2_2SellEdit.png';
import receipt from '../assets/images/efs/3_receipt.png';

import orders from '../assets/images/efs/5_orders.png';

import report_day from '../assets/images/efs/7_report_day.png';
import report_day_detail from '../assets/images/efs/10_report_day_detail.png';
import report_month from '../assets/images/efs/8_report_month.png';
import report_year from '../assets/images/efs/9_report_year.png';

import Setting_main_menu_3 from '../assets/images/efs/Setting_main_menu_3.png';
import void_1 from '../assets/images/efs/13_void_1.png';
import void_result from '../assets/images/efs/14_void_result.png';

import void_inside from '../assets/images/efs/15_void_inside.png';
import void_inside_result from '../assets/images/efs/16_void_inside_result.png';

import Setting_main_menu_4 from '../assets/images/efs/Setting_main_menu_4.png';
import Receipt_detail from '../assets/images/efs/Receipt_detail.png';

import receipt_cancel from '../assets/images/efs/19_receipt_cancel.png';
import receipt_cancel_product from '../assets/images/efs/20_receipt_cancel_product.png';

export default function EFS() {
  const { t } = useTranslation();
  const ioslink = 'https://apps.apple.com/us/app/easyforsale/id1214689554';
  return (
    <div className="app-page">
      <AppHeader />

      <div class="appContainer efs">
        <img src={efsLogo} alt="efs_logo" />
        <h1>{t('EasyForSale')}</h1>

        <div className="subtitle">{t('A point of sale (POS) application.')}</div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>

        <div>
          <div className="bullet">
            <img src={bullet} alt="bullet" /> <span>{t('WHY EasyForSale')}</span>
          </div>
          <div>{t('A POINT OF SALE (POS) APPLICATION.')}</div>
          <div>{t('It is easy to use and convenient for all business owners who look for a standalone application.')}</div>

          <div className="bigImg">
            <img src={easyforsale} alt="easyforsale" />
          </div>
          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Set Up')}</span>
            </div>

            <div>{t('SHOP NAME, CURRENCEY, AND RECEIPT PAPER SIZE.')}</div>
            <div className="appSteps">
              <img src={Setting_main_menu_1} alt="Setting_main_menu_1" />
              <img src={Setting_1shop_nameSetting} alt="Setting_1shop_nameSetting" />
              <img src={Setting_2currency} alt="Setting_2currency" />
              <img src={Setting_3paperSize} alt="Setting_3paperSize" />
            </div>
          </div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Products')}</span>
            </div>

            <div>{t('ADD PRODUCTS (NAME, PRICE).')}</div>
            <div className="appSteps">
              <img src={Setting_main_menu_2} alt="Setting_main_menu_2" />
              <img src={product} alt="product" />
            </div>

            <div>{t('EDIT PRODUCTS (NAME, PRICE).')}</div>
            <div className="appSteps">
              <img src={ProductToEdit} alt="ProductToEdit" />
              <img src={editProduct} alt="editProduct" />
            </div>
          </div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('SELLING')}</span>
            </div>

            <div>{t('Take orders')}</div>
            <div>
              <img src={sell} alt="sell" />
            </div>

            <div>{t('Search for the products')}</div>
            <div>
              <img src={search} alt="search" />
            </div>

            <div>{t('Check orders')}</div>
            <div className="appSteps">
              <img src={sell_check} alt="sell_check" />
              <img src={SellEdit} alt="SellEdit" />
            </div>

            <div>{t('Export a receipt')}</div>
            <div>
              <img src={receipt} alt="receipt" />
            </div>
          </div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('ORDERS')}</span>
            </div>

            <div>{t('- See all orders that have been submitted')}</div>
            <div>{t('- Check off when the order has been delivered.')}</div>
            <div>
              <img src={orders} alt="orders" />
            </div>
          </div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('REPORTS')}</span>
            </div>

            <div>{t('Daily')}</div>
            <div className="appSteps">
              <img src={report_day} alt="report_day" />
              <img src={report_day_detail} alt="report_day_detail" />
            </div>

            <div>{t('Monthly')}</div>
            <div>
              <img src={report_month} alt="report_month" />
            </div>

            <div>{t('Yearly')}</div>
            <div>
              <img src={report_year} alt="report_year" />
            </div>
          </div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('VOID')}</span>
            </div>

            <div>{t('VOID ORDERS')}</div>
            <div className="appSteps">
              <img src={Setting_main_menu_3} alt="Setting_main_menu_3" />
              <img src={void_1} alt="void_1" />
              <img src={void_result} alt="void_result" />
            </div>

            <div>{t('VOID Products')}</div>
            <div className="appSteps">
              <img src={void_inside} alt="void_inside" />
              <img src={void_inside_result} alt="void_inside_result" />
            </div>
          </div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('RECEIPT')}</span>
            </div>

            <div>{t('receipt records')}</div>
            <div className="appSteps">
              <img src={Setting_main_menu_4} alt="Setting_main_menu_4" />
              <img src={Receipt_detail} alt="Receipt_detail" />
              <img src={receipt_cancel_product} alt="receipt_cancel_product" />
            </div>

            <div>{t('Example of receipts (void)')}</div>
            <div className="appSteps">
              <img src={receipt_cancel} alt="receipt_cancel" />
              <img src={receipt_cancel_product} alt="receipt_cancel_product" />
            </div>
            <div>{t('It is perfect for all entrepreneurs.')}</div>
          </div>

          <div className="store-link">
            <a href={ioslink} target="_blank" rel="noreferrer">
              <img src={ioslogo} alt="ios_logo" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
