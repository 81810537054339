import App from './components/app'
import Header from './components/header'
import Footer from './components/footer'

function Home() {
    return (
        <div className="App h-screen">
            <Header />

            <div class="container" id="container">
                <div class="app-display">
                    <App name="NumberBoostBrain" app="nbb" info="Practice Math boosts the brain." ioslink="https://apps.apple.com/us/app/numberboostbrain/id1612314673" />

                    <App name="Pie Budget" app="pb" info="Help log your income & expenses" ioslink="https://apps.apple.com/us/app/piebudget/id1610757702" />

                    <App name="EasyForSale" app="efs" info="A point of sale (POS) application." ioslink="https://apps.apple.com/us/app/easyforsale/id1214689554" />

                    <App
                        name="Easy TimeStamp"
                        app="ets"
                        info="Help you log your daily activities."
                        ioslink="https://apps.apple.com/us/app/easy-timestamp/id1016324747?ls=1"
                        playlink="https://play.google.com/store/apps/details?id=com.nathtale.easyTimeStamp"
                        gallerylink="https://appgallery.huawei.com/#/app/C102697373"
                    />

                    <App name="MoreToTime" app="mtt" info="A continuous countdown timer." ioslink="https://apps.apple.com/us/app/moretotime/id1028231220" />

                    <App name="MoreToRead" app="mtr" info="A perfect app for book lovers." ioslink="https://apps.apple.com/us/app/moretoread/id1133111814" />

                    <App name="Tale yourself" app="tys" info="Help you keep encouraging yourself." ioslink="https://apps.apple.com/th/app/taleyourself/id6475626122" />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
