import mtrLogo from '../assets/images/mtr/logo.png';
import ioslogo from '../assets/images/icon_appstore.svg';
import bullet from '../assets/images/bullet.png';

import AppHeader from '../components/appheader';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';

import phone_screen_1 from '../assets/images/mtr/phone_screen_1.png';
import phone_screen_2 from '../assets/images/mtr/phone_screen_2.png';
import phone_screen_3 from '../assets/images/mtr/phone_screen_3.png';
import phone_screen_4 from '../assets/images/mtr/phone_screen_4.png';
import phone_screen_5 from '../assets/images/mtr/phone_screen_5.png';
import phone_screen_6 from '../assets/images/mtr/phone_screen_6.png';

export default function MTR() {
  const { t } = useTranslation();
  const ioslink = 'https://apps.apple.com/us/app/moretoread/id1133111814';
  return (
    <div className="app-page">
      <AppHeader />

      <div class="appContainer mtr">
        <img src={mtrLogo} alt="mtr_logo" />
        <h1>{t('MoreToRead')}</h1>

        <div className="subtitle">{t('A perfect app for book lovers.')}</div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>

        <div>
          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('WHY MoreToRead')}</span>
          </div>
          <div>{t('MoreToRead is perfect for book lovers.')}</div>
          <div>{t('It can help you count the number of books you have read.')}</div>
          <div>{t('You can add information of books you have finished reading to the application, then it will do the rest for you.')}</div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Add book information')}</span>
            </div>
            <div>
              <img src={phone_screen_1} alt="phone_screen_1" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('See the total count of books in years')}</span>
            </div>
            <div>
              <img src={phone_screen_2} alt="phone_screen_2" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('In a given year, see the total count of books in months')}</span>
            </div>
            <div>
              <img src={phone_screen_3} alt="phone_screen_3" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('See all the books in a given month')}</span>
            </div>
            <div>
              <img src={phone_screen_4} alt="phone_screen_4" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('You can also see all the books')}</span>
            </div>
            <div>{t('From this page you can edit and delete book information.')}</div>
            <div>
              <img src={phone_screen_5} alt="phone_screen_5" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('You can search for a book you like to see the information')}</span>
            </div>
            <div>
              <img src={phone_screen_6} alt="phone_screen_6" />
            </div>
          </div>
        </div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
