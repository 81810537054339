import tysLogo from '../assets/images/tys/logo.png';
import ioslogo from '../assets/images/icon_appstore.svg';
import bullet from '../assets/images/bullet.png';

import AppHeader from '../components/appheader';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';

import Home_1 from '../assets/images/tys/1_en.png';
import Home_2 from '../assets/images/tys/2_en.png';
import Home_3 from '../assets/images/tys/3_en.png';
import Home_4 from '../assets/images/tys/4_en.png';
import Home_5 from '../assets/images/tys/5_en.png';
import Home_edit from '../assets/images/tys/edit_en.png';


export default function TYS() {
  const { t } = useTranslation();
  const ioslink = 'https://apps.apple.com/th/app/taleyourself/id6475626122';
  return (
    <div className="app-page">
      <AppHeader />

      <div class="appContainer tys">
        <img src={tysLogo} alt="tys_logo" />
        <h1>{t('Tale yourself')}</h1>

        <div class="subtitle">{t('Help you keep encouraging yourself.')}</div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>

        <div>
          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('WHY Tale yourself')}</span>
          </div>

          <div>{t('Tale yourself can help you keep encouraging yourself by adding lists of text to remind yourself to be motivated.')}</div>
          <div>{t('This application comes in handy if you own an Apple Watch.')}</div>
          <div>{t('This application let you send text added on your phone to be shown on the watch.')}</div>

          <div className="appSteps">
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Home page')}</span>
            </div>
            <div>
              <img src={Home_1} alt="Home_1" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Add new text')}</span>
              <div>{t('- If your apple watch is opening the app')}</div>
              <div>{t('- Can send the text to your watch')}</div>
            </div>
            <div>
              <img src={Home_2} alt="Home_2" />
              <img src={Home_3} alt="Home_3" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Can choose icon')}</span>
            </div>
            <div>
                <img src={Home_4} alt="Home_4" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Can see the whole list of text you have added')}</span>
            </div>
            <div>
              <img src={Home_5} alt="Home_5" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Can edit the text and icon')}</span>
            </div>
            <div>
              <img src={Home_edit} alt="Home_edit" />
            </div>
          </div>
        </div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
