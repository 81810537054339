import { useTranslation } from 'react-i18next'
import ioslogo from '../assets/images/icon_appstore.svg'
import playlogo from '../assets/images/icon_googleplay.svg'
import gallerylogo from '../assets/images/icon_huaweiappgallery.svg'
import { Link } from 'react-router-dom'

function App({ name, app, info, ioslink, playlink, gallerylink }) {
    const image = require('../assets/images/' + app + '/logo.png')
    const { t } = useTranslation()
    return (
        <div className="box">
            <div className="body-box">
                <Link to={`/${app}`}>
                    <div className="app-box">
                        <img className="app-logo bounce" src={image} alt="logo" />
                    </div>
                    <div className="app-title">{t(name)}</div>
                </Link>
                <div className="app-info">{t(info)}</div>
            </div>
            <div className="store">
                <div className="store-logo">
                    <a href={ioslink} target="_blank" rel="noreferrer">
                        <img src={ioslogo} alt="ios_logo" />
                    </a>
                </div>
                <div className="store-logo">
                    {playlink && (
                        <a href={playlink} target="_blank" rel="noreferrer">
                            <img src={playlogo} alt="gplay_logo" />
                        </a>
                    )}
                </div>
                <div className="store-logo">
                    {gallerylink && (
                        <a href={gallerylink} target="_blank" rel="noreferrer">
                            <img src={gallerylogo} alt="gallery_logo" />
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default App
