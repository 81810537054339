import nbbLogo from '../assets/images/nbb/logo.png'
import ioslogo from '../assets/images/icon_appstore.svg'
import bullet from '../assets/images/bullet.png'

import AppHeader from '../components/appheader'
import Footer from '../components/footer'
import { useTranslation } from 'react-i18next'

import H1 from '../assets/images/nbb/H1.png'
import H2 from '../assets/images/nbb/H2.png'
import H3 from '../assets/images/nbb/H3.png'
import H4 from '../assets/images/nbb/H4.png'

export default function NBB() {
    const { t } = useTranslation()
    const ioslink = 'https://apps.apple.com/us/app/numberboostbrain/id1612314673'
    return (
        <div className="app-page">
            <AppHeader />

            <div class="appContainer nbb">
                <img src={nbbLogo} alt="mtr_logo" />
                <h1>{t('NumberBoostBrain')}</h1>

                <div class="subtitle">{t('Practicing simple Math every day helps boost the brain power.')}</div>

                <div className="store-link">
                    <a href={ioslink} target="_blank" rel="noreferrer">
                        <img src={ioslogo} alt="ios_logo" />
                    </a>
                </div>

                <div>
                    <div className="bullet">
                        <img src={bullet} alt="bullet" />
                        <span>{t('WHY NumberBoostBrain')}</span>
                    </div>

                    <div>{t('You can practice simple Math (add, minus, multiply, divide)')}</div>
                    <div>
                        <img src={H2} alt="H2" />
                    </div>

                    <div className="appSteps">
                        <div className="bullet">
                            <img src={bullet} alt="bullet" />
                            <span>{t('Choices')}</span>
                            <div>{t('- can choose number of questions.')}</div>
                            <div>{t('- can choose max range of the two numbers.')}</div>
                        </div>
                        <div>
                            <img src={H1} alt="H1" />
                        </div>

                        <div className="bullet">
                            <img src={bullet} alt="bullet" />
                            <span>{t('Records')}</span>
                            <div>{t('can see the records of how well you do for each quiz.')}</div>
                        </div>
                        <div>
                            <img src={H4} alt="H4" />
                            <img src={H3} alt="H3" />
                        </div>
                    </div>
                </div>

                <div className="store-link">
                    <a href={ioslink} target="_blank" rel="noreferrer">
                        <img src={ioslogo} alt="ios_logo" />
                    </a>
                </div>
            </div>

            <Footer />
        </div>
    )
}
