import pbLogo from '../assets/images/pb/logo.png';
import ioslogo from '../assets/images/icon_appstore.svg';
import bullet from '../assets/images/bullet.png';

import AppHeader from '../components/appheader';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';

import Home_1 from '../assets/images/pb/Home_1.png';
import Home_2 from '../assets/images/pb/Home_2.png';
import Home_3 from '../assets/images/pb/Home_3.png';
import Home_4 from '../assets/images/pb/Home_4.png';
import H1_Add_Budget from '../assets/images/pb/H1_Add_Budget.png';
import H2_New_Cateogry from '../assets/images/pb/H2_New_Cateogry.png';
import H3_Report_Custom from '../assets/images/pb/H3_Report_Custom.png';
import H3_Report from '../assets/images/pb/H3_Report.png';
import H4_Budget from '../assets/images/pb/H4_Budget.png';

export default function PB() {
  const { t } = useTranslation();
  const ioslink = 'https://apps.apple.com/us/app/piebudget/id1610757702';
  return (
    <div className="app-page">
      <AppHeader />

      <div class="appContainer pb">
        <img src={pbLogo} alt="mtr_logo" />
        <h1>{t('Pie Budget')}</h1>

        <div class="subtitle">{t('Help log your income & expenses')}</div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>

        <div>
          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('WHY Pie Budget')}</span>
          </div>

          <div>{t('An easy way to log your income and expenses. You can see the total budget in pie charts.')}</div>
          <div>{t('You can submit data in categories as well.')}</div>
          <div>{t('This app is perfect for everybody who wants to keep track on the personal finance.')}</div>

          <div className="appSteps">
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Add new budget')}</span>
            </div>
            <div>
              <img src={Home_1} alt="Home_1" />
              <img src={H1_Add_Budget} alt="H1_Add_Budget" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Add new categories')}</span>
              <div>{t('- Add new categories for categorizing your budget.')}</div>
              <div>{t('- Can also see or update categories.')}</div>
            </div>
            <div>
              <img src={Home_2} alt="Home_2" />
              <img src={H2_New_Cateogry} alt="H2_New_Cateogry" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Reports by day, week, month, year or custom')}</span>
              <div>{t('Can see reports by day, week, month, year or can custom start and end date in pie charts.')}</div>
            </div>
            <div>
              <img src={Home_3} alt="Home_3" />
              <img src={H3_Report} alt="H3_Report" />
              <img src={H3_Report_Custom} alt="H3_Report_Custom" />
            </div>

            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('Budget logged.')}</span>
              <div>{t('Can see or update the budget.')}</div>
            </div>
            <div>
              <img src={Home_4} alt="Home_4" />
              <img src={H4_Budget} alt="H4_Budget" />
            </div>
          </div>
        </div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
