import mttLogo from '../assets/images/mtt/logo.png';
import ioslogo from '../assets/images/icon_appstore.svg';
import bullet from '../assets/images/bullet.png';

import AppHeader from '../components/appheader';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';

import phonescreen1 from '../assets/images/mtt/phonescreen1.png';
import phonescreen2 from '../assets/images/mtt/phonescreen2.png';

export default function MTT() {
  const { t } = useTranslation();
  const ioslink = 'https://apps.apple.com/us/app/moretotime/id1028231220';
  return (
    <div className="app-page">
      <AppHeader />

      <div class="appContainer mtt">
        <img src={mttLogo} alt="mtt_logo" />
        <h1>{t('MoreToTime')}</h1>

        <div className="subtitle">{t('A continuous countdown timer.')}</div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>

        <div>
          <div className="bullet">
            <img src={bullet} alt="bullet" />
            <span>{t('WHY MoreToTime')}</span>
          </div>
          <div>{t('MoreToTime is an unusual countdown timer.')}</div>
          <div>
            <span>{t('First')}</span>, {t('MoreToTime provides a countdown timer in a row.')}
          </div>
          <div>{t('For example, you can time 15 seconds, 20 seconds, and 60seconds continuously by not having to reset a new time.')}</div>
          <div>{t('Just add time that you need next to one another and it will run in a row automatically.')}</div>
          <div>
            <span>{t('Second')}</span>, {t('you don\'t have to keep resetting the time.')}
          </div>
          <div>{t('You can add as many countdown times as you want.')}</div>
          <div>{t('when you want to use it just choose the time that you want and click play button.')}</div>

          <div>
            <div className="bullet">
              <img src={bullet} alt="bullet" />
              <span>{t('If you are a Toefl test taker')}</span>
            </div>

            <div>{t('You can use MoreToTime to time yourself while practicing for the test.')}</div>
            <div>
              <img src={phonescreen1} alt="phonescreen1" />
            </div>

            <div>{t('While the application is running, you can switch to use other applications (as long as it takes only a few minutes), such as Voice memos to record your voice.')}</div>
            <div>{t('There will be a notification alerted when the time is up.')}</div>

            <div>
              <img src={phonescreen2} alt="phonescreen2" />
            </div>
          </div>
        </div>

        <div className="store-link">
          <a href={ioslink} target="_blank" rel="noreferrer">
            <img src={ioslogo} alt="ios_logo" />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
