import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            'Contact us': 'Contact us: ',

            'Pie Budget': 'Pie Budget',
            EasyForSale: 'EasyForSale',
            'Easy TimeStamp': 'Easy TimeStamp',
            MoreToTime: 'MoreToTime',
            MoreToRead: 'MoreToRead',
            'Tale yourself': 'Tale yourself',

            'Help log your income & expenses': 'Help log your income & expenses',
            'A point of sale (POS) application.': 'A point of sale (POS) application.',
            'Help you log your daily activities.': 'Help you log your daily activities.',
            'A continuous countdown timer.': 'A continuous countdown timer.',
            'A perfect app for book lovers.': 'A perfect app for book lovers.',
            'Help you keep encouraging yourself.': 'Help you keep encouraging yourself.',

            'WHY Pie Budget': 'WHY Pie Budget ?',
            'WHY EasyForSale': 'WHY EasyForSale ?',
            'WHY Easy TimeStamp': 'WHY Easy TimeStamp ?',
            'WHY MoreToTime': 'WHY MoreToTime ?',
            'WHY MoreToRead': 'WHY MoreToRead ?',

            'An easy way to log your income and expenses. You can see the total budget in pie charts.':
                'An easy way to log your income and expenses. You can see the total budget in pie charts.',
            'You can submit data in categories as well.': 'You can submit data in categories as well.',
            'This app is perfect for everybody who wants to keep track on the personal finance.':
                'This app is perfect for everybody who wants to keep track on the personal finance.',
            'Add new budget': 'Add new budget',
            'Add new categories': 'Add new categories',
            '- Add new categories for categorizing your budget.': '- Add new categories for categorizing your budget.',
            '- Can also see or update categories.': '- Can also see or update categories.',
            'Reports by day, week, month, year or custom': 'Reports by day, week, month, year or custom',
            'Can see reports by day, week, month, year or can custom start and end date in pie charts.':
                'Can see reports by day, week, month, year or can custom start and end date in pie charts.',
            'Budget logged.': 'Budget logged.',
            'Can see or update the budget.': 'Can see or update the budget.',

            'A POINT OF SALE (POS) APPLICATION.': 'A POINT OF SALE (POS) APPLICATION.',
            'It is easy to use and convenient for all business owners who look for a standalone application.':
                'It is easy to use and convenient for all business owners who look for a standalone application.',
            'Set Up': 'Set Up',
            'SHOP NAME, CURRENCEY, AND RECEIPT PAPER SIZE.': 'SHOP NAME, CURRENCEY, AND RECEIPT PAPER SIZE.',
            Products: 'Products',
            'ADD PRODUCTS (NAME, PRICE).': 'ADD PRODUCTS (NAME, PRICE).',
            'EDIT PRODUCTS (NAME, PRICE).': 'EDIT PRODUCTS (NAME, PRICE).',
            SELLING: 'SELLING',
            'Take orders': 'Take orders',
            'Search for the products': 'Search for the products',
            'Check orders': 'Check orders',
            'Export a receipt': 'Export a receipt',
            ORDERS: 'ORDERS',
            '- See all orders that have been submitted': '- See all orders that have been submitted',
            '- Check off when the order has been delivered.': '- Check off when the order has been delivered.',
            REPORTS: 'REPORTS',
            Daily: 'Daily',
            Monthly: 'Monthly',
            Yearly: 'Yearly',
            VOID: 'VOID',
            'VOID ORDERS': 'VOID ORDERS',
            'VOID Products': 'VOID Products',
            RECEIPT: 'RECEIPT',
            'receipt records': 'receipt records',
            'Example of receipts (void)': 'Example of receipts (void)',
            'It is perfect for all entrepreneurs.': 'It is perfect for all entrepreneurs.',

            'It makes your phone work as a timestamp machine.': 'It makes your phone work as a timestamp machine.',
            'You can use to log your daily activities.': 'You can use to log your daily activities.',
            'It is easy to use.': 'It is easy to use.',
            'STEP 1': 'STEP 1',
            'Just click add': 'Just click add',
            'STEP 2': 'STEP 2',
            'Type whatever you want to log then click save': 'Type whatever you want to log then click save',
            'STEP 2 (OPTION)': 'STEP 2 (OPTION)',
            'In case you always add same timestamp, you can use add quicklist': 'In case you always add same timestamp, you can use add quicklist',
            'Use Quicklist': 'Use Quicklist',
            'STEP 3': 'STEP 3',
            'Click PDF button to export file PDF as a report of your logs': 'Click PDF button to export file PDF as a report of your logs',
            'ENTRY MENU': 'ENTRY MENU',
            'Export reports & Clear entries.': 'Export reports & Clear entries.',
            'EXPORT REPORTS': 'EXPORT REPORTS',
            'daily, monthly, yearly, or all of the reports.': 'daily, monthly, yearly, or all of the reports.',
            'An example of a daily report.': 'An example of a daily report.',
            'CLEAR ENTRIES': 'CLEAR ENTRIES',
            'daily, monthly, yearly, or all of the entries.': 'daily, monthly, yearly, or all of the entries.',
            'An example of clearing a daily entry.': 'An example of clearing a daily entry.',
            'If you run an office and you want a timestamp': 'If you run an office and you want a timestamp',
            'Your staff can use this app to stamp work time': 'Your staff can use this app to stamp work time',
            'Use AddQuickList to add timestamp faster.': 'Use AddQuickList to add timestamp faster.',
            'If you usually work at home': 'If you usually work at home',
            'You can use Easy Timestamp to log your working time.': 'You can use Easy Timestamp to log your working time.',
            'It helps you know how long you work each day.': 'It helps you know how long you work each day.',
            'If you own a restaurant': 'If you own a restaurant',
            'You can use Easy Timestamp to log your orders.': 'You can use Easy Timestamp to log your orders.',
            'Doing business, even a second is important.': 'Doing business, even a second is important.',
            'If you love to be healthy': 'If you love to be healthy',
            'You can use Easy Timestamp to log your meal time.': 'You can use Easy Timestamp to log your meal time.',
            'Eating on time is crucial': 'Eating on time is crucial',
            'This app can help you log your meal time easily': 'This app can help you log your meal time easily',

            'MoreToTime is an unusual countdown timer.': 'MoreToTime is an unusual countdown timer.',
            First: 'First',
            'MoreToTime provides a countdown timer in a row.': 'MoreToTime provides a countdown timer in a row.',
            'For example, you can time 15 seconds, 20 seconds, and 60seconds continuously by not having to reset a new time.':
                'For example, you can time 15 seconds, 20 seconds, and 60seconds continuously by not having to reset a new time.',
            'Just add time that you need next to one another and it will run in a row automatically.':
                'Just add time that you need next to one another and it will run in a row automatically.',
            Second: 'Second',
            "you don't have to keep resetting the time.": "you don't have to keep resetting the time.",
            'You can add as many countdown times as you want.': 'You can add as many countdown times as you want.',
            'when you want to use it just choose the time that you want and click play button.':
                'when you want to use it just choose the time that you want and click play button.',
            'If you are a Toefl test taker': 'If you are a Toefl test taker',
            'You can use MoreToTime to time yourself while practicing for the test.': 'You can use MoreToTime to time yourself while practicing for the test.',
            'While the application is running, you can switch to use other applications (as long as it takes only a few minutes), such as Voice memos to record your voice.':
                'While the application is running, you can switch to use other applications (as long as it takes only a few minutes), such as Voice memos to record your voice.',
            'There will be a notification alerted when the time is up.': 'There will be a notification alerted when the time is up.',

            'MoreToRead is perfect for book lovers.': 'MoreToRead is perfect for book lovers.',
            'It can help you count the number of books you have read.': 'It can help you count the number of books you have read.',
            'You can add information of books you have finished reading to the application, then it will do the rest for you.':
                'You can add information of books you have finished reading to the application, then it will do the rest for you.',
            'Add book information': 'Add book information',
            'See the total count of books in years': 'See the total count of books in years',
            'In a given year, see the total count of books in months': 'In a given year, see the total count of books in months',
            'See all the books in a given month': 'See all the books in a given month',
            'You can also see all the books': 'You can also see all the books',
            'From this page you can edit and delete book information.': 'From this page you can edit and delete book information.',
            'You can search for a book you like to see the information': 'You can search for a book you like to see the information',

            NumberBoostBrain: 'NumberBoostBrain',
            'Practice Math boosts the brain.': 'Practice Math boosts the brain.',
            'Practicing simple Math every day helps boost the brain power.': 'Practicing simple Math every day helps boost the brain power.',
            'WHY NumberBoostBrain': 'WHY NumberBoostBrain ?',
            'You can practice simple Math (add, minus, multiply, divide)': 'You can practice simple Math (add, minus, multiply, divide)',
            Choices: 'Choices',
            '- can choose number of questions.': '- can choose number of questions.',
            '- can choose max range of the two numbers.': '- can choose max range of the two numbers.',
            Records: 'Records',
            'can see the records of how well you do for each quiz.': 'can see the records of how well you do for each quiz.',

            'Tale yourself can help you keep encouraging yourself by adding lists of text to remind yourself to be motivated.': 'Tale yourself can help you keep encouraging yourself by adding lists of text to remind yourself to be motivated.',
            'This application comes in handy if you own an Apple Watch.': 'This application comes in handy if you own an Apple Watch.',
            'This application let you send text added on your phone to be shown on the watch.':'This application let you send text added on your phone to be shown on the watch.',
            'Home page': 'Home page',
            'Add new text': 'Add new text',
            '- If your apple watch is opening the app':'- If your apple watch is opening the app',
            '- Can send the text to your watch':'- Can send the text to your watch',
            'Can choose icon': 'Can choose icon',
            'Can see the whole list of text you have added':'Can see the whole list of text you have added',
            'Can edit the text and icon': 'Can edit the text and icon',


        },
    },
    th: {
        translation: {
            'Contact us': 'ติดต่อเรา: ',
            'Pie Budget': 'พายบัดเจ็ท',
            EasyForSale: 'อีซีฟอร์เซล',
            'Easy TimeStamp': 'อีซี่ไทม์สแตมป์',
            MoreToTime: 'มอร์ทูไทม์',
            MoreToRead: 'มอร์ทูรีด',
            'Tale yourself': 'เทล ยัวร์เซล',

            'Help log your income & expenses': 'บันทึกรายรับรายจ่าย ทำงบดุล แบบง่ายๆ',
            'A point of sale (POS) application.': 'โปรแกรมขายหน้าร้าน',
            'Help you log your daily activities.': 'บันทึกเวลาแบบง่าย',
            'A continuous countdown timer.': 'จับเวลาต่อเนื่อง',
            'A perfect app for book lovers.': 'นับจำนวนหนังสือที่อ่าน',
            'Help you keep encouraging yourself.': 'ให้กำลังใจตัวเอง',

            'WHY Pie Budget': 'ทำไมคุณควรใช้ พายบัดเจ็ท',
            'WHY EasyForSale': 'ทำไมคุณควรใช้ อีซีฟอร์เซล',
            'WHY Easy TimeStamp': 'ทำไมคุณควรใช้ อีซี่ไทม์สแตมป์',
            'WHY MoreToTime': 'ทำไมคุณควรใช้ มอร์ทูไทม์',
            'WHY MoreToRead': 'ทำไมคุณควรใช้ มอร์ทูรีด',
            'WHY Tale yourself': 'ทำไมคุณควรใช้ เทล ยัวร์เซล',

            'An easy way to log your income and expenses. You can see the total budget in pie charts.':
                'แอบพลิเคชั่นที่ช่วยคุณทำรายรับรายจ่าย ทำงบดุล แบบง่ายๆ คุณสามารถบันทึกข้อมูลการใช้จ่ายเงิน พร้อมทั้งสามารถดูสรุปการใช้จ่ายเป็นกราฟวงกลมได้',
            'You can submit data in categories as well.': 'สามารถเลือกเพิ่มข้อมูลเป็นหมวดหมู่ได้',
            'This app is perfect for everybody who wants to keep track on the personal finance.': 'เหมาะสำหรับทุกคนที่อยากบันทึกข้อมูลการใช้จ่ายเงิน',
            'Add new budget': 'เพิ่มข้อมูลรายรับรายจ่าย',
            'Add new categories': 'เพิ่มหมวดหมู่ใหม่',
            '- Add new categories for categorizing your budget.': '- เพิ่มหมวดหมู่ เพื่อบันทึกรายรับรายจ่ายในหมวดหมู่นั้น',
            '- Can also see or update categories.': '- สามารถดูหรือแก้ไขหมวดหมู่ได้',
            'Reports by day, week, month, year or custom': 'รายงานรายวัน, รายสัปดาห์, รายเดือน, รายปี หรือรายการที่กำหนดเอง',
            'Can see reports by day, week, month, year or can custom start and end date in pie charts.':
                'สามารถดูรายงานรายวัน, รายสัปดาห์, รายเดือน, รายปี หรือรายการที่กำหนดเอง ในรูปแบบกราฟวงกลม',
            'Budget logged.': 'ข้อมูลรายรับรายจ่าย',
            'Can see or update the budget.': 'สามารถแก้ไขข้อมูลรายรับรายจ่ายได้',

            'A POINT OF SALE (POS) APPLICATION.': 'โปรแกรมขายหน้าร้าน',
            'It is easy to use and convenient for all business owners who look for a standalone application.':
                'อีซีฟอร์เซล เป็นแอปพลิเคชั่นที่ใช้งานง่าย เหมาะสำหรับใช้งานเดี่ยวๆ ไม่ต้องทำการ login เข้าระบบใดๆ',
            'Set Up': 'ตั้งค่า',
            'SHOP NAME, CURRENCEY, AND RECEIPT PAPER SIZE.': 'ชื่อร้านค้า, สกุลเงิน, ขนาดใบเสร็จ',
            Products: 'สินค้า',
            'ADD PRODUCTS (NAME, PRICE).': 'เพิ่มสินค้า (ชื่อสินค้า, ราคา)',
            'EDIT PRODUCTS (NAME, PRICE).': 'แก้ไขสินค้า (ชื่อสินค้า, ราคา)',
            SELLING: 'ขายสินค้า',
            'Take orders': 'รับออเดอร์',
            'Search for the products': 'ค้นหาสินค้า',
            'Check orders': 'ตรวจสอบออเดอร์',
            'Export a receipt': 'ออกใบเสร็จ',
            ORDERS: 'ออเดอร์',
            '- See all orders that have been submitted': '- ออเดอร์ทั้งหมด',
            '- Check off when the order has been delivered.': '- กดเลือกสินค้าที่ส่งมอบแล้วออกได้',
            REPORTS: 'รายงาน',
            Daily: 'รายวัน',
            Monthly: 'รายเดือน',
            Yearly: 'รายปี',
            VOID: 'ยกเลิก',
            'VOID ORDERS': 'ยกเลิก ออเดอร์',
            'VOID Products': 'ยกเลิก สินค้า',
            RECEIPT: 'ใบเสร็จ',
            'receipt records': 'เรียกใบเสร็จดูย้อนหลัง',
            'Example of receipts (void)': 'ตัวอย่างใบเสร็จ (ยกเลิก)',
            'It is perfect for all entrepreneurs.': 'แอปพลิเคชั่นนี้เหมาะกับร้านค้าทุกประเภท',

            'It makes your phone work as a timestamp machine.': 'แอบพลิเคชั่นนี้ช่วยทำงานเหมือนเครื่องตอกบัตร',
            'You can use to log your daily activities.': 'คุณสามารถใช้เก็บข้อมูลกิจกรรมที่คุณทำในแต่ละวัน',
            'It is easy to use.': 'แอบพลิเคชั่นนี้ใช้งานง่ายมาก',
            'STEP 1': 'ขั้นที่ 1',
            'Just click add': 'กดปุ่มเพิ่ม',
            'STEP 2': 'ขั้นที่ 2',
            'Type whatever you want to log then click save': 'กรณีที่คุณมีข้อมูลที่คุณเพิ่มบ่อยๆ แนะนำฟังก์ชั่น เพิ่มค่าล่วงหน้า',
            'STEP 2 (OPTION)': 'ขั้นที่ 2 (ตัวเลือก)',
            'In case you always add same timestamp, you can use add quicklist': 'กรณีที่คุณมีข้อมูลที่คุณเพิ่มบ่อยๆ แนะนำฟังก์ชั่น เพิ่มค่าล่วงหน้า',
            'Use Quicklist': 'เลือกเพิ่มค่าที่กำหนดไว้ล่วงหน้า',
            'STEP 3': 'ขั้นที่ 3',
            'Click PDF button to export file PDF as a report of your logs': 'กดปุ่มไฟล์ เพื่อทำการส่งออกเป็นไฟล์ PDF',
            'ENTRY MENU': 'เมนูข้อมูล',
            'Export reports & Clear entries.': 'ออกรายงาน & ลบข้อมูล.',
            'EXPORT REPORTS': 'ออกรายงาน',
            'daily, monthly, yearly, or all of the reports.': 'รายงาน รายวัน รายเดือน รายปี หรือ ทั้งหมด',
            'An example of a daily report.': 'ตัวอย่าง รายงานรายวัน',
            'CLEAR ENTRIES': 'ลบข้อมูล',
            'daily, monthly, yearly, or all of the entries.': 'ลบข้อมูล รายวัน รายเดือน รายปี หรือ ทั้งหมด',
            'An example of clearing a daily entry.': 'ตัวอย่างการลบข้อมูลรายวัน',
            'If you run an office and you want a timestamp': 'ถ้าที่บริษัทของคุณต้องการเครื่องตอกบัตร',
            'Your staff can use this app to stamp work time': 'คุณสามารถใช้แอบพลิเคชั่นนี้บันทึกเวลาเข้าออกการทำงานของพนักงานของคุณได้',
            'Use AddQuickList to add timestamp faster.': 'คุณสามารถใช้ ฟังก์ชั่นเพิ่มค่าล่วงหน้า เพื่อเพิ่มความรวดเร็วในการบันทึกเวลา',
            'If you usually work at home': 'ถ้าคุณทำงานที่บ้าน',
            'You can use Easy Timestamp to log your working time.': 'คุณสามารถใช้แอบพลิเคชั่นนี้บันทึกเวลาการทำงานของคุณ',
            'It helps you know how long you work each day.': 'แอบพลิเคชั่นนี้ช่วยคุณทราบเวลาการทำงานของคุณในแต่ละวัน',
            'If you own a restaurant': 'ถ้าคุณเป็นเจ้าของร้านอาหาร',
            'You can use Easy Timestamp to log your orders.': 'คุณสามารถใช้แอบพลิเคชั่นนี้จดออเดอร์อาหารของลูกค้าได้',
            'Doing business, even a second is important.': 'ในการทำธุรกิจ เวลาระดับวินาทีมีความสำคัญมาก',
            'If you love to be healthy': 'ถ้าคุณเป็นคนรักสุขภาพ',
            'You can use Easy Timestamp to log your meal time.': 'คุณสามารถใช้แอบพลิเคชั่นนี้บันทึกเวลาการทานอาหารของคุณได้',
            'Eating on time is crucial': 'การกินอาหารตรงเวลามีความสำคัญมาก',
            'This app can help you log your meal time easily': 'แอบพลิเคชั่นนี้จะช่วยบันทึกเวลาการทานอาหารของคุณได้อย่างง่ายดาย',

            'MoreToTime is an unusual countdown timer.': 'แอบพลิเคชั่นนี้ ช่วยจับเวลาในลักษณะที่ไม่เหมือนกับนาฬิกาจับเวลาทั่วไป',
            First: 'ข้อดีอย่างแรก',
            'MoreToTime provides a countdown timer in a row.': 'มอร์ทูไทม์ ช่วยจับเวลาแบบต่อเนื่องเป็นแถว',
            'For example, you can time 15 seconds, 20 seconds, and 60seconds continuously by not having to reset a new time.':
                'ตัวอย่างเช่น คุณสามารถจับเวลา 15วินาที 20วินาที 60วินาที แบบต่อเนื่อง โดยที่คุณไม่ต้องหยุดเพื่อตั้งเวลาใหม่เลย',
            'Just add time that you need next to one another and it will run in a row automatically.':
                'แค่ตั้งเวลาที่คุณต้องการจับเวลาล่วงหน้าให้ต่อๆกันไป ระบบจะทำงานให้อัตโนมัติ',
            Second: 'ข้อดีอย่างที่สอง',
            "you don't have to keep resetting the time.": 'คุณไม่ต้องหยุดเพื่อตั้งเวลาใหม่เลย',
            'You can add as many countdown times as you want.': 'คุณสามารถตั้งเวลาจับเวลาที่ต้องการได้หลายครั้งตามที่คุณต้องการ',
            'when you want to use it just choose the time that you want and click play button.': 'เมื่อต้องการจับเวลา แค่เลือกเวลาที่ต้องการ แล้วกดปุ่มเล่น',
            'If you are a Toefl test taker': 'ถ้าคุณเตรียมตัวสอบ Toefl (โทเฟล)',
            'You can use MoreToTime to time yourself while practicing for the test.': 'แอบพลิเคชั่นนี้เหมาะกับคุณอย่างมาก คุณสามารถใช้จับเวลาเพื่อเตรียมตัวสอบ',
            'While the application is running, you can switch to use other applications (as long as it takes only a few minutes), such as Voice memos to record your voice.':
                'ขณะที่คุณใช้แอบพลิเคชั่นนี้ คุณสามารถเปลี่ยนไปใช้แอบพลิเคชั่นอื่นๆได้ (ประมาณสองสามนาที), เช่น แอพพลิเคชั่นอัดเสียง',
            'There will be a notification alerted when the time is up.':
                'เมื่อเวลาที่ตั้งไว้จบลง จะมีการแจ้งเตือนขึ้นที่หน้าจอThere will be a notification alerted when the time is up.',

            'MoreToRead is perfect for book lovers.': 'มอร์ทูรีดเหมาะสำหรับคนรักการอ่าน',
            'It can help you count the number of books you have read.': 'แอบพลิเคชั่นนี้จะช่วยนับจำนวนหนังสือที่คุณอ่านไปแล้วทั้งหมด',
            'You can add information of books you have finished reading to the application, then it will do the rest for you.':
                'แค่เพิ่มข้อมูลหนังสือที่อ่านแล้วลงในแอบพลิเคชั่น ที่เหลือแอบพลิเคชั่นจะทำงานให้คุณเอง',
            'Add book information': 'เพิ่มข้อมูลหนังสือ',
            'See the total count of books in years': 'ดูจำนวนหนังสือที่คุณอ่านในแต่ละปี',
            'In a given year, see the total count of books in months': 'ดูจำนวนหนังสือที่คุณอ่านในแต่ละเดือนของปีๆหนึ่ง',
            'See all the books in a given month': 'ดูจำนวนหนังสือที่อ่านในแต่เดือนนั้นๆ',
            'You can also see all the books': 'ดูจำนวนหนังสือทั้งหมด',
            'From this page you can edit and delete book information.': 'ที่หน้าต่างนี้คุณสามารถคลิกเพื่อแก้ไข หรือ ลบข้อมูลหนังสือได้',
            'You can search for a book you like to see the information': 'ค้นหาหนังสือตามชื่อหนังสือ',

            NumberBoostBrain: 'นัมเบอร์บูสท์เบรน',
            'Practice Math boosts the brain.': 'คิดเลขช่วยฝึกสมอง',
            'Practicing simple Math every day helps boost the brain power.': 'คิดเลขเป็นประจำทุกวัน ช่วยฝึกสมองให้ทำงานอยู่ตลอดเวลา',
            'WHY NumberBoostBrain': 'ทำไมคุณควรใช้ นัมเบอร์บูสท์เบรน',
            'You can practice simple Math (add, minus, multiply, divide)': 'คุณสามารถฝึกคิดเลขแบบง่ายๆ ทุกวัน (บวก ลบ คูณ หาร)',
            Choices: 'ตัวเลือก',
            '- can choose number of questions.': '- สามารถเลือกจำนวนคำถามได้',
            '- can choose max range of the two numbers.': '- สามารถเลือกขอบเขตของตัวเลข 2 ตัวเลข ที่จะแสดงในคำถามได้',
            Records: 'บันทึก',
            'can see the records of how well you do for each quiz.': 'สามารถดูบันทึกได้ว่าคิดเลขแต่ละรอบใช้เวลานานแค่ไหน',

            'Tale yourself can help you keep encouraging yourself by adding lists of text to remind yourself to be motivated.': 'เทล ยัวร์เซล เป็น แอบพลิเคชั่น ให้กำลังใจตัวเอง โดยการเพิ่มข้อความ เพื่อไว้เตือนตัวเอง',
            'This application comes in handy if you own an Apple Watch.': 'มีฟังก์ชั่นเพิ่ม หากคุณมี แอปเปิ้ล วอช',
            'This application let you send text added on your phone to be shown on the watch.':'แอบนี้ สามารถส่งข้อความ ไป ยัง แอปเปิ้ล วอช ได้ด้วย',
            'Home page': 'หน้าแรก',
            'Add new text': 'เพิ่มข้อความใหม่',
            '- If your apple watch is opening the app':'- กรณีที่ แอปเปิ้ล วอช ของคุณเปิดแอบพลิเคชั่นอยู่',
            '- Can send the text to your watch':'- สามารถส่งข้อความไปยัง แอปเปิ้ล วอช ได้',
            'Can choose icon': 'สามารถเลือกไอคอนได้',
            'Can see the whole list of text you have added':'สามารถเห็น ลิสต์ ทั้งหมด ที่เพิ่มไว้ได้',
            'Can edit the text and icon': 'สามารถแก้ไข ข้อความและ ไอคอน ได้',
        },
    },
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    })

export default i18n
