import logo from '../assets/images/logo.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import nbbLogo from '../assets/images/nbb/logo.png'
import pbLogo from '../assets/images/pb/logo.png'
import efsLogo from '../assets/images/efs/logo.png'
import etsLogo from '../assets/images/ets/logo.png'
import mttLogo from '../assets/images/mtt/logo.png'
import mtrLogo from '../assets/images/mtr/logo.png'
import tysLogo from '../assets/images/tys/logo.png'

import React, { useEffect } from 'react'

export default function AppHeader() {
    const { t, i18n } = useTranslation()
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    useEffect(() => {
        changeLanguage(i18n.language === undefined ? 'en' : i18n.language)
    }, [i18n.language])
    // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <header className="App-header">
            <div className="nav-container">
                <div className="nav-box">
                    <Link to="/nbb">
                        <div className="app-nav-link">
                            <img src={nbbLogo} alt="logo" />
                            <div className="navtitle">{t('NumberBoostBrain')}</div>
                        </div>
                    </Link>
                    <Link to="/pb">
                        <div className="app-nav-link">
                            <img src={pbLogo} alt="logo" />
                            <div className="navtitle">{t('Pie Budget')}</div>
                        </div>
                    </Link>
                    <Link to="/efs">
                        <div className="app-nav-link">
                            <img src={efsLogo} alt="logo" />
                            <div className="navtitle">{t('EasyForSale')}</div>
                        </div>
                    </Link>
                    <Link to="/ets">
                        <div className="app-nav-link">
                            <img src={etsLogo} alt="logo" />
                            <div className="navtitle">{t('Easy TimeStamp')}</div>
                        </div>
                    </Link>
                    <Link to="/mtt">
                        <div className="app-nav-link">
                            <img src={mttLogo} alt="logo" />
                            <div className="navtitle">{t('MoreToTime')}</div>
                        </div>
                    </Link>
                    <Link to="/mtr">
                        <div className="app-nav-link">
                            <img src={mtrLogo} alt="logo" />
                            <div className="navtitle">{t('MoreToRead')}</div>
                        </div>
                    </Link>
                    <Link to="/tys">
                        <div className="app-nav-link">
                            <img src={tysLogo} alt="logo" />
                            <div className="navtitle">{t('Tale yourself')}</div>
                        </div>
                    </Link>
                </div>
                <div className="nav-logo">
                    <div className="head-box">
                        <Link to="/">
                            <img src={logo} className="logo" alt="logo" />
                        </Link>
                    </div>

                    <div className="head-box lang">
                        <div className={`langbtn ${i18n.language === 'en' && 'defaultLn'}`} onClick={() => changeLanguage('en')}>
                            EN
                        </div>
                        |
                        <div className={`langbtn ${i18n.language === 'th' && 'defaultLn'}`} onClick={() => changeLanguage('th')}>
                            ไทย
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
