import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NBB from './pages/nbb';
import PB from './pages/pb';
import EFS from './pages/efs';
import ETS from './pages/ets';
import MTR from './pages/mtr';
import MTT from './pages/mtt';
import TYS from './pages/tys';
import ETSPP from './pages/etsprivacypolicy';
import React from 'react';
import './assets/styles/index.css';
import './assets/styles/app.css';
import Home from './Home';
import './i18n';

const rootElement = document.getElementById('root');
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="nbb" element={<NBB />} />
      <Route path="pb" element={<PB />} />
      <Route path="efs" element={<EFS />} />
      <Route path="efsth" element={<EFS />} />
      <Route path="ets" element={<ETS />} />
      <Route path="etsth" element={<ETS />} />
      <Route path="easytimestamp" element={<ETS />} />
      <Route path="mtr" element={<MTR />} />
      <Route path="mtrth" element={<MTR />} />
      <Route path="mtt" element={<MTT />} />
      <Route path="mttth" element={<MTT />} />
      <Route path="tys" element={<TYS />} />
      <Route path="tysth" element={<TYS />} />
      <Route path="etsprivacypolicy" element={<ETSPP />} />
    </Routes>
  </BrowserRouter>,
  rootElement,
);
