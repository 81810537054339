import ButtonMailto from '../utils/ButtonMailto';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="App-footer">
      <div>Copyright © {new Date().getFullYear()} NATHTALE.</div>
      <div>
        {t('Contact us')}
        <ButtonMailto label="admin@nathtale.com" mailto="mailto:admin@nathtale.com" />
      </div>
    </footer>
  );
}
